export const ContactFormSubmitted = () => (
  <div className="container center-align">
    <img
      className="responsive-img"
      src={require("../../../images/mail_sent.svg")}
      alt="mail_sent"
      width="30%"
    />
    <h4>Thank you for your message</h4>
  </div>
);
